import _getEnvironment from "./getEnvironment";
var exports = {};
const isBrowser = _getEnvironment("type") === "browser";
const resolveURL = isBrowser ? s => new URL(s, window.location.href).href : s => s; // eslint-disable-line

exports = options => {
  const opts = {
    ...options
  };
  ["corePath", "workerPath", "langPath"].forEach(key => {
    if (options[key]) {
      opts[key] = resolveURL(opts[key]);
    }
  });
  return opts;
};
export default exports;